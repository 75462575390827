
import "./App.css";
// import Landing from "./components/landing";
import SignInSide from "./components/newLanding";

function App() {
  // return <Landing/>;
  return <SignInSide/>;
}

export default App;