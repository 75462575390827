import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../redux/features/auth/authSilice";
import { Alert, Box, Checkbox, CircularProgress, Container } from "@mui/material";
import { styled } from "@mui/material/styles";
import { blue } from "@mui/material/colors";
import Grid from "@mui/material/Grid";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
const useStyles = makeStyles((theme) => ({
  form: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  input: {
    width: "100%",
    marginBottom: "10px",
  },
}));
const ColorButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText(blue[100]),
  backgroundColor: blue[50],
  "&:hover": {
    backgroundColor: blue[100],
  },
}));
// const ColorButton = styled(Button)(({ theme }) => ({
//   color: theme.palette.getContrastText(blue[100]),
//   backgroundColor: blue[50],
//   "&:hover": {
//     backgroundColor: blue[100],
//   },
// }));

function LoginForm({ title, type }) {
  const buttonStyle = {
    textTransform: "none",
    color: "white",
    backgroundColor: "#F63854",
  };
  const buttonStyleText = {
    textTransform: "none",
    color: "#F63854",
    radius:'8px'
  };
  const customCheckboxStyle = {
    color: '#E6E6E6',
    borderWidth: '1px',
  };

  let hrefValue = "";
  let hrefValueAsset = "https://ncia.asset.foxmy.io/forgot-password";
  let hrefValueProject = "https://ncia.project.foxmy.io/forgot-password";

  if (type === "asset") {
    hrefValue = hrefValueAsset;
  } else {
    hrefValue = hrefValueProject;
  }

  const classes = useStyles();

  const dispatch = useDispatch();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isShown, setIsShown] = useState(false);
  const { isSuccess, isLoading, isError } = useSelector((state) => state.auth);
  // function displayToast() {
  //   return <Alert severity="error">This is an error alert — check it out!</Alert>
  // }
  const handleSubmit = async (e) => {
    if (!username || username === "" || !password || password === "") {
      // { displayToast() }
    } else {
      e.preventDefault();
      if (type === "project") {
        try {
          // Get the iframe element by its ID
          const iframe = document.getElementById("myIframe");
          const wind = iframe.contentWindow;
          const getToken = async () => {
            const res = await dispatch(login({ username, password, type }));
            // console.log("the response", res);
            const data = {
              accessToken: res.payload.access,
              refreshToken: res.payload.refresh,
            };
            // console.log(data);
            wind.postMessage(JSON.stringify(data), "*");
          };
          const answer = await getToken();
          // console.log("answer", answer);
          window.location.href = "https://ncia.project.foxmy.io";
        } catch (e) {
          // {displayToast()}

          console.log("what went wrong", e);
        }
      }

      if (type === "asset") {
        try {
          const iframe = document.getElementById("myIframe-asset");
          const wind = iframe.contentWindow;
          const getToken = async () => {
            const res = await dispatch(login({ username, password, type }));
            console.log("the response", res);
            const data = {
              access: res.payload.access,
              refresh: res.payload.refresh,
            };
            console.log(data);
            wind.postMessage(JSON.stringify(data), "*");
          };
          await getToken();
          window.location.href = "https://ncia.asset.foxmy.io";
        } catch (e) {
          console.log("what went wrong", e);
        }
      } else {
        console.log("not working");
      }
    }
  };
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSubmit(e);
    }
  };

  return (
    <>
      {/* <Grid justifyContent={'center'} alignItems={'center'}> */}
      {isError ? (
        <Alert sx={{ mb: 2 }} severity="error">
          Invalid credentials or server error!
        </Alert>
      ) : null}
      {isSuccess ? (
        <Alert sx={{ mb: 2 }} severity="success">
          Successful Login. Hold on tight, redirecting
        </Alert>
      ) : null}
      <iframe
        title="myIframe"
        src="https://ncia.project.foxmy.io/login"
        width="800"
        height="600"
        id="myIframe"
        frameBorder="0"
        style={{ display: "none" }}
      ></iframe>
      <iframe
        title="myIframe-asset"
        src="https://ncia.asset.foxmy.io/login"
        width="800"
        height="600"
        id="myIframe-asset"
        frameBorder="0"
        style={{ display: "none" }}
      ></iframe>

      <Container sx={{ p: 1 }} onKeyPress={handleKeyPress}>
        {/* <img
          src="/ncer.png"
          alt="Ncer"
          style={{
            width: '20rem',
            display: 'block',
            margin: 'auto',
            marginBottom: '1rem'
          }}
        /> */}
            <Grid container spacing={2}>
      <Grid item xs={12}>
        <Box sx={{ pb:2 }}>
          <Typography sx={{ fontWeight: 600,fontSize:22,fontFamily: 'Raleway',textAlign:'center'}}>
            LOG IN
          </Typography>
        </Box>

        <TextField
         sx={{
    // Remove the width property
    // margin: 2,
    mb: 2,
    bgcolor: 'white',
    '& .MuiInputBase-root': {
      borderRadius: '10px',
      border: 'none',
    },
    '&.MuiTextField-root': {
      borderRadius: '10px',
    },
  }}
  className={classes.input}
  InputProps={{
    disableUnderline: true,
  }}
  variant="outlined"
  placeholder="Enter your email"
  onChange={(e) => setUsername(e.target.value)}
        />
        <TextField
          sx={{
            // width: "2rem",
            // margin:2,
            bgcolor: "white",
            '& .MuiInputBase-root': {
              borderRadius: '10px',
              border: 'none',
            },
            '&.MuiTextField-root': {
              borderRadius: '10px',
            }
          }}
          className={classes.input}
          placeholder="Enter your password"
          type={isShown ? "text" : "password"}
          onChange={(e) => setPassword(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setIsShown(!isShown)}>
                  {isShown ? (
                    <VisibilityIcon />
                  ) : (
                    <VisibilityOffIcon />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
</Grid>
</Grid>
      </Container>
      <Grid
        container
        sx={{
          mt: 2,
          pl:3,
          pr:3,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', whiteSpace: 'nowrap', mr: 5, flexDirection: { xs: 'column', sm: 'row' } }}>
          <Checkbox className="check-keep-log" />
          <Typography sx={{fontFamily: 'Raleway',fontSize:'14px', fontWeight:400}}>
            Keep logged in
          </Typography>
        </Box>
        <Button sx={{ p: 1, mt: { xs: 2, sm: 0 } }} href={hrefValue}>
          <Typography style={buttonStyleText} sx={{ whiteSpace: 'nowrap',fontFamily: 'Raleway',fontSize:'14px', fontWeight:600 }} variant="h9">
            Forgot password
          </Typography>
        </Button>
      </Grid>
      {/* <Grid
        sx={{
          mt: 4,
          mb: 5,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      > */}
        <ColorButton
          type="submit"
          variant="contained"
          className=""
          style={buttonStyle}
          sx={{ paddingRight: 15, paddingLeft: 15, mt: 2,mb:4, fontWeight: "100", width: '90%', whiteSpace: 'nowrap', boxShadow: "none" }}
          onClick={handleSubmit}
        >
          {isLoading ? (
            <Box sx={{ display: "flex" }}>
              <CircularProgress style={{width: "25px" , height: "25px"}} />
            </Box>
          ) : (
            "Sign in"
          )}
        </ColorButton>
      {/* </Grid> */}
      {/* </Grid> */}
    </>
  );
}

export default LoginForm;
