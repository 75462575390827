import * as React from "react";
import { useState } from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import LoginForm from "./loginForm";
import { useSelector } from "react-redux";
import slider1 from "../assets/slider1.png";
import slider2 from "../assets/slider2.png";
import slider3 from "../assets/slider3.png";
// import Swiper core and required modules
import { Pagination , Autoplay } from "swiper/modules";

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

// function Copyright(props) {
//   return (
//     <Typography
//       variant="body2"
//       color="text.secondary"
//       align="center"
//       {...props}
//     >
//       {"Copyright © "}
//       <Link color="inherit" href="https://www.infinitywave.io/FOX">
//         Infinity Wave
//       </Link>{" "}
//       {new Date().getFullYear()}
//       {"."}
//     </Typography>
//   );
// }
const customColor = "#F63854";

const buttonStyle = {
  background: `linear-gradient(to top, ${customColor} 5%, transparent 5%)`,
  color: "white",
};
const buttonStyle2 = {
  backgroundColor: "white",
  color: "#E6E6E6",
  // boxShadow: '0px 0px 1px rgba(0, 0, 0, 0.2)',
};

const defaultTheme = createTheme();

export default function SignInSide() {
  const [activeTab, setActiveTab] = useState("asset");

  const { isSuccess, isLoading, isError } = useSelector((state) => state.auth);
  const toggleLogin = (id) => {
    setActiveTab(id);
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    console.log({
      email: data.get("email"),
      password: data.get("password"),
    });
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Grid container component="main" sx={{ height: "100vh" }}>
        <CssBaseline />
        <Grid
          className="left-wrapper"
          item
          sx={{
            backgroundRepeat: "no-repeat",
            backgroundColor: (t) =>
              t.palette.mode === "light"
                ? "rgba(255, 255, 255, 0.7)"
                : t.palette.grey[900],
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <Swiper
            // install Swiper modules
            modules={[Pagination , Autoplay]}
            slidesPerView={1}
            spaceBetween={-2}
            loop
            pagination={{ clickable: true}}
            onSwiper={(swiper) => console.log(swiper)}
            onSlideChange={() => console.log("slide change")}
            // autoplay={{ delay: 3000 }}
          >
            <SwiperSlide>
              <div className="slider-page">
                <img src={slider1} alt="" />
                <div className="text-wraper">
                  <h3>
                    Integrated Asset <br /> Management System
                  </h3>
                  <p>Northern Corridor Implementation Authority</p>
                </div>
                <span>
                  Asset Management Unit | Corporate Services Department
                </span>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="slider-page">
                <img src={slider2} alt="" />
                <div className="text-wraper">
                  <h3>
                    Integrated Asset <br /> Management System
                  </h3>
                  <p>Northern Corridor Implementation Authority</p>
                </div>
                <span>
                  Asset Management Unit | Corporate Services Department
                </span>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="slider-page">
                <img src={slider3} alt="" />
                <div className="text-wraper">
                  <h3>
                    Integrated Asset <br /> Management System
                  </h3>
                  <p>Northern Corridor Implementation Authority</p>
                </div>
                <span>
                  Asset Management Unit | Corporate Services Department
                </span>
              </div>
            </SwiperSlide>
          </Swiper>
        </Grid>
        <Grid
          className="right-wrapper"
          item
          component={Paper}
          elevation={6}
          square
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              alignItems: "center",
              height: "100vh", // Set the maximum height as needed
              // overflowY: 'auto',  // Add vertical scrollbar if content overflows
              "&::-webkit-scrollbar": {
                width: "0.5em", // Width of the scrollbar
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "transparent", // Color of the thumb
              },
            }}
          >
            <div className="sidebar-wrapper">
              <img
                src="/ncer.png"
                alt="Ncer"
                style={{
                  width: "150px",
                  height: "auto",
                }}
              />
              <Grid item>
                <Grid
                  sx={{ width: "90%", ml: 2 }}
                  container
                  alignItems="center"
                  justifyContent="space-between"
                  paddingBottom={2}
                >
                  <Grid item sx={{ width: "50%" }}>
                    <Button
                      style={activeTab === "asset" ? buttonStyle : buttonStyle2}
                      sx={{
                        borderBottom: "1px solid #eee",
                        textTransform: "capitalize",
                        "&:hover": {
                          boxShadow: "none", // Remove button outline on hover
                        },
                        padding: "15px",
                        boxShadow: "none",
                        textAlign: "center",
                        width: "100%",
                      }}
                      variant={activeTab === "asset" ? "contained" : "none"}
                      onClick={() => toggleLogin("asset")}
                    >
                      <Typography
                        variant="h6"
                        sx={{
                          color: activeTab === "asset" ? customColor : "gray",
                          fontWeight: 500,
                          fontSize: "19px",
                        }}
                      >
                        Asset
                      </Typography>
                    </Button>
                  </Grid>
                  <Grid item sx={{ width: "50%" }}>
                    <Button
                      style={
                        activeTab === "project" ? buttonStyle : buttonStyle2
                      }
                      sx={{
                        borderBottom: "1px solid #eee",
                        textTransform: "capitalize",
                        "&:hover": {
                          boxShadow: "none",
                        },
                        padding: "15px",
                        textAlign: "center",
                        width: "100%",
                        boxShadow: "none",
                      }}
                      variant={activeTab === "project" ? "contained" : "none"}
                      onClick={() => toggleLogin("project")}
                    >
                      <Typography
                        sx={{
                          color: activeTab === "project" ? customColor : "gray",
                          fontSize: "19px",
                        }}
                        variant="h6"
                      >
                        Project
                      </Typography>
                    </Button>
                  </Grid>
                </Grid>
                <Grid container alignItems="center" flexDirection="column">
                  {activeTab === "asset" ? (
                    <LoginForm type="asset" title="Login" />
                  ) : (
                    <LoginForm type="project" title="Login" />
                  )}
                </Grid>
              </Grid>
            </div>
            <Grid
              container
              direction={"column"}
              alignItems={"center"}
              sx={{ mb: 3, justifyContent: "center", width: "100%" }}
            >
              <Grid item>
                <Typography className="copy-right">POWERED BY FOX</Typography>
              </Grid>
              <Grid item>
                <Typography className="copy-right">
                  &#169; infinity Wave sdn bhd
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}
